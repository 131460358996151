exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-tickets-js": () => import("./../../../src/pages/buy-tickets.js" /* webpackChunkName: "component---src-pages-buy-tickets-js" */),
  "component---src-pages-confirmation-ida-aalen-js": () => import("./../../../src/pages/confirmation-ida-aalen.js" /* webpackChunkName: "component---src-pages-confirmation-ida-aalen-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-confirmation-wait-list-js": () => import("./../../../src/pages/confirmation-wait-list.js" /* webpackChunkName: "component---src-pages-confirmation-wait-list-js" */),
  "component---src-pages-confirmation-workshop-js": () => import("./../../../src/pages/confirmation-workshop.js" /* webpackChunkName: "component---src-pages-confirmation-workshop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-name-ticket-js": () => import("./../../../src/pages/name-ticket.js" /* webpackChunkName: "component---src-pages-name-ticket-js" */),
  "component---src-pages-program-2020-js": () => import("./../../../src/pages/program2020.js" /* webpackChunkName: "component---src-pages-program-2020-js" */),
  "component---src-pages-program-2021-js": () => import("./../../../src/pages/program2021.js" /* webpackChunkName: "component---src-pages-program-2021-js" */),
  "component---src-pages-program-2022-js": () => import("./../../../src/pages/program2022.js" /* webpackChunkName: "component---src-pages-program-2022-js" */),
  "component---src-pages-program-2023-js": () => import("./../../../src/pages/program2023.js" /* webpackChunkName: "component---src-pages-program-2023-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-speakers-2022-js": () => import("./../../../src/pages/speakers2022.js" /* webpackChunkName: "component---src-pages-speakers-2022-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-pages-tickets-y-digital-js": () => import("./../../../src/pages/tickets-y-digital.js" /* webpackChunkName: "component---src-pages-tickets-y-digital-js" */),
  "component---src-pages-tickets-y-global-js": () => import("./../../../src/pages/tickets-y-global.js" /* webpackChunkName: "component---src-pages-tickets-y-global-js" */),
  "component---src-pages-tickets-y-oslo-js": () => import("./../../../src/pages/tickets-y-oslo.js" /* webpackChunkName: "component---src-pages-tickets-y-oslo-js" */),
  "component---src-pages-tickets-y-private-js": () => import("./../../../src/pages/tickets-y-private.js" /* webpackChunkName: "component---src-pages-tickets-y-private-js" */),
  "component---src-pages-tickets-y-workshop-js": () => import("./../../../src/pages/tickets-y-workshop.js" /* webpackChunkName: "component---src-pages-tickets-y-workshop-js" */),
  "component---src-pages-wait-list-js": () => import("./../../../src/pages/wait-list.js" /* webpackChunkName: "component---src-pages-wait-list-js" */),
  "component---src-pages-workshops-2022-js": () => import("./../../../src/pages/workshops2022.js" /* webpackChunkName: "component---src-pages-workshops-2022-js" */),
  "component---src-pages-workshops-2023-js": () => import("./../../../src/pages/workshops2023.js" /* webpackChunkName: "component---src-pages-workshops-2023-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-pages-y-stream-billett-js": () => import("./../../../src/pages/y-stream-billett.js" /* webpackChunkName: "component---src-pages-y-stream-billett-js" */),
  "component---src-pages-y-stream-confirmation-js": () => import("./../../../src/pages/y-stream-confirmation.js" /* webpackChunkName: "component---src-pages-y-stream-confirmation-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-speaker-js": () => import("./../../../src/templates/speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */),
  "component---src-templates-workshop-js": () => import("./../../../src/templates/workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */)
}


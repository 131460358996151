module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Y Oslo","short_name":"Y Oslo","start_url":"/","background_color":"#29CB7E","theme_color":"#29CB7E","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"de83607f92239bbcffba9b70ac38e409"},
    },{
      plugin: require('../node_modules/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"containerUrl":"https://netlife.containers.piwik.pro","siteId":"df2b5707-9edb-44e0-9dcb-7b841e2b5484","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
